html {
    background-color: black;
}

.about {
    text-align: left;
}

.about h3 {
    font-size: 48px;
}

.about p {
    font-size: 20px;
}

@media (max-width: 1200px) {
    .common-container .main-text {
        font-size: 48px;
    }
}

@media (max-width: 992px) {
    .about-text {
        margin-top: 30px;
    }

    .common-container .main-text {
        font-size: 32px;
    }
}
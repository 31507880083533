.App {
  text-align: center;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-style: normal;
  /* background-color: black; */
}

/* NAV */
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 65px;
  background-color: black;
}

.nav-container {
  max-width: 1576px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  display: flex;
  justify-content: space-between;
}

.nav-container a {
  font-size: 20px;
  color: #818181;
  text-decoration: none;
}

.nav-container a.active {
  color: white;
}

.right-nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 65px;
  justify-content: center;
}

.right-nav a {
  margin-right: 28px;
}

.right-nav a:hover {
  color: white;
}

.left-nav {
  display: flex;
  align-items: center;
  margin-left: 28px;
  font-size: 20px;
}


/* BODY CONTAINER  */

.common-container {
  padding-top: 90px;
  width: 100%;
  background-color: black;
  color: #FFFFFF;
}

.body-container {
  color: #FFFFFF;
  background-color: black;
  z-index: 10;
  position: fixed;
  top: 65px;
  padding-top: 25px;
  width: 100vw;
  height: 100vh;
}

.main-wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-left: 28px;
  padding-right: 28px;
  max-width: 1576px;
  text-align: left;
}

.body-container .main-text {
  font-weight: 500;
  font-size: 60px;
  line-height: 110%;
}

@media (max-width: 1200px) {
  .main-wrapper .main-text {
    font-size: 48px;
  }
}

@media (max-width: 992px) {
  .main-wrapper .main-text {
    font-size: 32px;
  }
}


/* WORKS LIST */

main.works-list {
  margin-top: 100vh;
  height: 200vh;
  color: brown;
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
  z-index: 50;
}

main.works-list .list-elem {
  min-height: 400px;
  width: 100%;
  z-index: 999;
}

main.works-list .list-elem img {
  max-width: 100%;
}

.works-list .img__wrap:hover .img__description {
  visibility: visible;
  opacity: 1;
}

.works-list .img__wrap {
  background-color: rgb(107, 106, 106);
}

.works-list .img__wrap .img__img {
  transition: opacity .2s, visibility .2s;
}

.works-list .img__wrap:hover .img__img {
  opacity: 0.3;
}

.works-list .img__description {
  background: rgba(29, 106, 154, 0.72);
  color: #fff;
  visibility: visible;
  opacity: 0;
  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}
/* WORK NAV */
.work-nav-wrapper {
    position: relative;
    bottom: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 60px;
}

.work-nav {
    width: 290px;
    height: 92px;
    background: #000000;
    border: 1px solid #FFFFFF;
    border-radius: 202px;
}

.nav-couter {
    position: relative;
    font-size: 32px;
    width: 100px;
    /* border: 1px solid yellow; */
    left: 95px;
    bottom: 14px;
    text-align: center;
}

.arrow {
    cursor: pointer;
    width: 30px;
    height: 36px;
}

.arrow-left {
    position: relative;
    top: 26px;
    left: 40px;
    transform: rotate(180deg);
    /* border: 1px solid blue; */
}

.arrow-right {
    position: relative;
    /* border: 1px solid blue; */
    bottom: 72px;
    left: 216px;
}

.line {
    width: 28px;
    position: relative;
    top: 15px;
    border-radius: 2px;
    border: 2px solid white;
}

.head-upper-line {
    width: 28px;
    border-radius: 2px;
    border-top: 4px solid white;
    transform: rotate(45deg);
    position: relative;
    top: 2px;
    /* bottom: 13px; */
    left: 5px;
}

.head-lower-line {
    width: 28px;
    border-radius: 2px;
    border-bottom: 4px solid white;
    transform: rotate(135deg);
    position: relative;
    top: 16px;
    left: 5px;
}
/* IMAGE */

.custom-main-img-container {
    position: relative;
}

.main-img {
    width: 100%;
    object-fit: cover;
}

.main-img-wrapper {
    position: relative;
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.main-img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #818181;
}

.custom-main-img-container:hover .main-img-overlay {
    opacity: 1;
}

.main-img-overlay {
    text-decoration: none;
    color: #FFFFFF;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.main-img-overlay h3.overlay-header {
    color: #FFFFFF;
    font-size: 90px;
    margin-top: 18px;
    margin-left: 30px;
}

.main-img-overlay .overlay-footer {
    color: #FFFFFF;
    position: absolute;
    bottom: 0px;
    font-size: 20px;
    margin-left: 30px;
    margin-bottom: 30px;
}

.main-img-overlay .overlay-footer p {
    color: #b8b8b8;
}

/* BUTTON - SEE*/

.button-h {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 40px 40px 67px;
    font-size: 26px;
    gap: 10px;
    background: #000000;
    border: 1px solid #FFFFFF;
    border-radius: 202px;
    width: 337px;
    height: 101px;
    cursor: pointer;
    color: white;
}

.button-h-a {
    text-decoration: none;
    color: white;
}

.button-text-h-overlay {
    position: relative;
    bottom: 41px;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .1s ease;
    color: white;
}

.button-h:hover .button-text-h-overlay {
    transition: .1s ease;
    opacity: 1;
}

.button-h:hover .button-text-h {
    transition: .1s ease;
    opacity: 0;
}

.button-h:hover {
    transition: .1s ease;
    background-color: #818181;
}

.footer-button {
    height: 200px;
}

@media (max-width: 992px) {
    .button-h {
        padding: 20px 25px 14px 35px;
        font-size: 5px;
        gap: 10px;
        border-radius: 202px;
        width: 226px;
        height: 68px;
        border-radius: 202px;
    }

    .button-h .button-text-h {
        font-size: 20px;
    }

    .button-h .button-text-h-overlay {
        font-size: 20px;
        bottom: 32px;
    }
}
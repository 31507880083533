h2.proj-header {
    padding-top: 32px;
    font-size: 50px;
    color: white;
    text-align: left;
}

.proj-desc {
    text-align: left;
}

.proj-desc-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #818181;
}

p.proj-desc-text {
    font-weight: 400;
    font-size: 30px;
}

.proj_img {
    width: 100%;
    margin-bottom: 30px;
}
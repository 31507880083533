.footer {
    text-align: left;
}

/* 
.inline {
    display: inline-block;
    text-decoration: none;
    color: white;
    cursor: pointer;
} */

.body-container .footer {
    position: absolute;
    width: 500px;
    bottom: 120px;
    display: flex;
    justify-content: space-between;
}

.common-container .footer {
    position: fixed;
    bottom: 30px;
    width: 500px;
    /* border: violet 2px dotted; */
}

.common-container .footer .main-text {
    font-size: 60px;
}

@media (max-width: 1200px) {
    .common-container .footer .main-text {
        font-size: 48px;
    }

    .common-container .footer {
        width: 420px;
    }

    .body-container .footer {
        width: 420px;
    }
}

@media (max-width: 992px) {
    .common-container .footer .main-text {
        font-size: 32px;
    }

    .common-container .footer {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 290px;
        position: static;
    }

    .body-container .footer {
        width: 290px;
    }
}
html {
    background-color: black;
}

.works-container {
    padding-top: 90px;
    width: 100%;
    background-color: black;
}

.custom-work-container {
    position: relative;
    width: 100%;
    color: #FFFFFF;
    margin-bottom: 28px;
}

.work-img {
    width: 52%;
    object-fit: cover;
    background-color: #0D0C0C;
}

.works-img-wrapper {
    opacity: 1;
    aspect-ratio: 1 / 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #0D0C0C;
}

.img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #818181;
    text-align: left;
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.custom-work-container:hover .img-overlay {
    opacity: 1;
}

.img-overlay h3.overlay-header {
    color: white;
    font-size: 90px;
    margin-top: 15px;
    margin-left: 20px;
}

.img-overlay .overlay-footer {
    position: absolute;
    color: white;
    bottom: 0px;
    font-size: 20px;
    margin-left: 30px;
    margin-bottom: 30px;
}

.img-overlay .overlay-footer p {
    color: #b8b8b8;
}